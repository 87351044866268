import React, { createContext, useContext, useState } from 'react';

const ListCardsContext = createContext();

export const ListCardsProvider = ({ children }) => {

    const [selectedCard, setSelectedCard] = useState(null);

    //Gestion del contenido de los Alert
    const [message, setMessage] = useState('Esto un mensaje de prueba');
    const [alertType, setAlertType] = useState('success');


    //Gestion del Alert del Header
    const [showAlertHeader, setShowAlertHeader] = useState(false);
    const openAlertHeader = () => {
        setShowAlertHeader(true);
    };
    const closeAlertHeader = () => {
        setShowAlertHeader(false);
    };

    //Gestion del modal Edicion de usuario
    const [showEditModal, setShowEditModal] = useState(false);
    const openEditUserModal = (card) => {
        setSelectedCard(card);
        setShowEditModal(true);
    };
    const closeEditUserModal = () => {
        setShowEditModal(false);
    };

    //Gestion del modal de borrar un usuario
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const openDeleteModal = (card) => {
        setSelectedCard(card);
        setShowDeleteModal(true);
    };
    const closeDeleteModal = () => {
        setShowDeleteModal(false);
    };

    //Gestion del modal de cambio de estado
    const [showChangeStateModal, setShowChangeStateModal] = useState(false);
    const openChangeStateModal = (card) => {
        setSelectedCard(card);
        setShowChangeStateModal(true);
    };
    const closeChangeStateModal = () => {
        setShowChangeStateModal(false);
    };

    const [apiSuccess, setApiSuccessSuccess] = useState(false);
    const changeApiSuccess = () => {
        setApiSuccessSuccess(!apiSuccess);
    };
    return (
        <ListCardsContext.Provider value={{
            apiSuccess,
            changeApiSuccess,
            closeChangeStateModal,
            openChangeStateModal,
            showChangeStateModal,
            closeDeleteModal,
            openDeleteModal,
            showDeleteModal,
            alertType,
            setAlertType,
            setMessage,
            message,
            openAlertHeader,
            closeAlertHeader,
            showAlertHeader,
            selectedCard,
            setSelectedCard,
            showEditModal,
            openEditUserModal,
            closeEditUserModal,
        }}>
            {children}
        </ListCardsContext.Provider>
    );
};

export const useListCards = () => {
    return useContext(ListCardsContext);
};
