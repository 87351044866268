import React, { useContext, useRef, useState } from 'react';
import imageProfile from '../assets/img/img-profile.jpg'
import AutoDismissAlert from './autoDismiss';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/authcontext';
import { NewUserAdminData } from '../schema/users';
import { API_URLS, MEDIA_URL } from '../api/apiConfig';
import { postWithAuth } from '../api/api';
import { useUser } from '../context/userContext';
import { ADMIN_USER } from '../routes/permissions';

const UserProfile = ({ user, isNewUser, isOwnUser }) => {

    const userContext = useUser();

    const [showAlertHeader, setShowAlertHeader] = useState(false);
    const [showAlertImage, setShowAlertImage] = useState(false);
    const [showAlertPassword, setShowAlertPassword] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null); // Estado para almacenar el archivo seleccionado
    const fileInputRef = useRef(null); // Ref para acceder al elemento de entrada de archivo

    const imagePic = user?.user_pic_url ? `${MEDIA_URL}${user.user_pic_url}` : imageProfile;

    const handleUploadButtonClick = () => {
        // Abrir el cuadro de diálogo de selección de archivo al hacer clic en el botón
        fileInputRef.current.click();
    };
    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
    };

    const [message, setMessage] = useState('Esto un mensaje de prueba');
    const [alertType, setAlertType] = useState('danger');

    const navigate = useNavigate();
    const { authData } = useContext(AuthContext);

    let userData = {};

    if (user) {
        userData = {
            name: user.name,
            lastname: user.lastname,
            email: user.email,
            is_admin: user.is_admin,
            is_superadmin: user.is_superadmin,
        };
    } else {
        userData = {
            name: '',
            lastname: '',
            email: '',
            is_admin: true,
            is_superadmin: false,
            password: ''
        };
    }
    const [userAdminData, setUserAdminData] = useState(userData);


    const handleInputChange = (event) => {
        const { id, value, type, checked } = event.target;
        setUserAdminData((prevData) => {
            const updatedUser = {
                ...prevData,
                [id]: type === 'checkbox' ? !prevData[id] : value,
            };
            return updatedUser;
        });
    };

    const handleNewAdmin = async (e) => {
        e.preventDefault();
        try {
            let response;
            if (!isNewUser) {
                response = await postWithAuth(API_URLS.ADMIN_PUT(user.sub), authData.token, userAdminData);
            } else {
                response = await postWithAuth(API_URLS.ADMIN_POST, authData.token, userAdminData);
            }

            if (response.status === 'success') {

                if (!isNewUser) {
                    userContext.closeEditUserModal()
                    userContext.launchRefreshUserList()
                } else {
                    userContext.closeNewUserModal()
                    userContext.launchRefreshUserList()
                }

            } else if (response.status === 404) {
                setMessage(response.data.detail);
                setShowAlertHeader(true);
            } else {
                setMessage("Error inesperado");
                setShowAlertHeader(true);
            }

        } catch (error) {
            console.error('Error creating user admin:', error);
            setMessage("Ha ocurrido un error inesperado");
            setShowAlertHeader(true);
        }
    };

    return (
        <div className="container-fluid max-w-screen-md vstack gap-6">
            {showAlertHeader && (
                <div>
                    {/* Contenido para cuando isOkSaveConfig o isOkSavePassword son verdaderos */}
                    <AutoDismissAlert message={message} alertType={alertType} onClose={() => setShowAlertHeader(false)} />
                    {/* Más contenido para cuando isOkSaveConfig o isOkSavePassword son verdaderos */}
                </div>
            )}
            {!isNewUser && (
                <div className="card">
                    <div className="card-body">
                        <div className="d-flex align-items-center">
                            <div>
                                <div className="d-flex align-items-center"><a href="#"
                                    className="avatar avatar-lg text-white"><img alt="..."
                                        src={imagePic}></img></a>

                                    <div className="ms-4"><span className="h4 d-block mb-0">{user.name} {user.lastname}</span> <a href="#"
                                        className="text-sm font-semibold text-muted"></a></div>
                                    {showAlertImage && (
                                        <div className="m-4">
                                            {/* Contenido para cuando isOkSaveConfig o isOkSavePassword son verdaderos */}
                                            <AutoDismissAlert message={message} alertType={alertType} onClose={() => setShowAlertHeader(false)} />
                                            {/* Más contenido para cuando isOkSaveConfig o isOkSavePassword son verdaderos */}
                                        </div>
                                    )}
                                </div>
                            </div>

                            <input
                                type="file"
                                accept="image/*"
                                onChange={handleFileUpload}
                                style={{ display: 'none' }} // Estilo para ocultar el elemento de entrada de archivo
                                ref={fileInputRef} // Ref para acceder al elemento de entrada de archivo
                            />
                            {isOwnUser && (
                                <div className="ms-auto">
                                    <button type="button" className="btn btn-sm btn-neutral" onClick={handleUploadButtonClick}>
                                        Subir foto
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
            <div>
                <div className="mb-5">
                    <h4>Información de usuario</h4>
                </div>
                <form onSubmit={handleNewAdmin}>
                    <div className="row g-5">
                        <div className="col-md-6">
                            <div>
                                <label className="form-label">Nombre</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="name"
                                    defaultValue={isNewUser ? '' : user.name}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div>
                                <label className="form-label">Apellidos</label>
                                <input
                                    type="text"
                                    id="lastname"
                                    className="form-control"
                                    defaultValue={isNewUser ? '' : user.lastname}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                        {!isOwnUser && (
                            <div className="col-md-6">
                                <div>
                                    <label className="form-label" htmlFor="email">
                                        Email
                                    </label>
                                    <input
                                        id="email"
                                        type="email"
                                        className="form-control"
                                        defaultValue={isNewUser ? '' : user.email}
                                        onChange={handleInputChange}
                                        readOnly={isNewUser ? false : true}

                                    />
                                </div>
                            </div>
                        )}
                        {isNewUser && (
                            <div class="col-md-6">
                                <div>
                                    <label class="form-label" for="password">
                                        Contraseña
                                    </label>
                                    <input
                                        id="password"
                                        type="password"
                                        class="form-control"
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </div>
                        )}

                        {isNewUser || authData.type_user === ADMIN_USER ? (
                            <div className="col-12">
                                <div className="form-check form-switch">
                                    <input
                                        className="form-check-input"
                                        htmlFor="is_superadmin"
                                        type="checkbox"
                                        name="is_superadmin"
                                        id="is_superadmin"
                                        onChange={handleInputChange}
                                        checked={userAdminData.is_superadmin}
                                    />
                                    <label className="form-check-label" htmlFor="accept_calls">Usuario administrador</label>

                                </div>
                            </div>
                        ) : null}

                        <div className="col-12 text-end">
                            <button type="submit" className="btn btn-sm btn-primary">Guardar</button></div>
                    </div>
                </form>
            </div>
            {!isNewUser && (
                <hr className="my-6"></hr>
            )}

        </div>
    );
};

export default UserProfile;