import React, { useContext, useState } from 'react';
import AutoDismissAlert from '../../components/autoDismiss';
import imageProfile from '../../assets/img/img-profile.jpg';
import { API_URLS, MEDIA_URL } from '../../api/apiConfig';
import { postWithAuth } from '../../api/api';
import { AuthContext } from '../../context/authcontext';
import { useListCards } from '../../context/listCardsContext';
import CommentCard from './comment';

const CardContent = ({ card }) => {

    const [changeCard, setCard] = useState(card);
    const { authData } = useContext(AuthContext);
    const listCardsContext = useListCards();

    const [activeTab, setActiveTab] = useState('qr'); // Establecer la pestaña activa inicial

    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
    };

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;

        setCard((prevData) => {
            const updatedCard = {
                ...prevData,
                [name]: type === 'checkbox' ? !prevData[name] : value,
                background_image: selectedImageIndex,
            };
            return updatedCard;
        });
    };

    const handleSave = async (e) => {
        e.preventDefault();
        try {

            const response = await postWithAuth(API_URLS.CARDS_PUT(card.id), authData.token, changeCard);

            if (response.status === 'success') {
                listCardsContext.changeApiSuccess()
                listCardsContext.closeEditUserModal()
            } else if (response.status === 404) {
                setAlertType('danger');
                setMessage(response.data.detail);
                setShowAlertHeader(true);
            } else {
                setAlertType('danger');
                setMessage("Error inesperado");
                setShowAlertHeader(true);
            }

        } catch (error) {
            console.error('Error creating user admin:', error);
            setMessage("Ha ocurrido un error inesperado");
            setShowAlertHeader(true);
        }
    };

    const importAll = (r) => {
        let images = {};
        r.keys().forEach((item, index) => {
            images[item.replace('./', '')] = r(item);
        });
        return images;
    };

    const images = importAll(require.context('../../assets/img/background/', false, /\.(png|jpg|svg)$/));
    const [selectedImageIndex, setSelectedImageIndex] = useState(card.background_image);
    const [showAlertHeader, setShowAlertHeader] = useState(false);
    const [message, setMessage] = useState('Esto es un mensaje de prueba');
    const [alertType, setAlertType] = useState('success');

    return (
        <div className="container-fluid max-w-screen-md vstack gap-6">
            {showAlertHeader && (
                <div>
                    <AutoDismissAlert message={message} alertType={alertType} onClose={() => setShowAlertHeader(false)} />
                    {/* Más contenido para cuando isOkSaveConfig o isOkSavePassword son verdaderos */}
                </div>
            )}
            <form>
                <div className="d-flex mb-4 align-items-center">
                    <div>
                        <div className="d-flex align-items-center">
                            <div className="">
                                <a href="#" className="text-sm font-semibold text-muted"></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-5 mb-5">
                    <h4><u>Imagen de fondo</u></h4>
                </div>
                <div className="card">
                    <div className="card-body">
                        <div className="mt-4 d-flex gap-2 scrollable-x" id="image-container">
                            {Object.keys(images).map((imageName, index) => (
                                <div
                                    key={index}
                                    className={`col-auto ${selectedImageIndex === index ? 'selected-image' : 'image-card-backgound-opacity'}`}
                                >
                                    <img
                                        alt="..."
                                        src={images[imageName]} // Usamos .default para acceder a la URL de la imagen
                                        className="rounded heigh-card-background"
                                        onClick={() => {
                                            setSelectedImageIndex(index)
                                            handleInputChange({
                                                target: {
                                                    name: 'background_image',
                                                    value: index,
                                                },
                                            });
                                        }
                                        }
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                <div>
                    <div className="mt-5 mb-5">
                        <h4><u>Datos Personales y te contacto</u></h4>
                    </div>
                    <div className="row g-5">
                        <div className="col-md-6">
                            <div>
                                <label className="form-label">Nombre</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="name"
                                    onChange={handleInputChange}
                                    defaultValue={card.name}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div>
                                <label className="form-label">Apellidos</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="lastname"
                                    onChange={handleInputChange}
                                    defaultValue={card.lastname}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div>
                                <label className="form-label" htmlhtmlFor="email">
                                    Email
                                </label>
                                <input
                                    type="email"
                                    className="form-control"
                                    defaultValue={card.email}
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div>
                                <label className="form-label" htmlhtmlFor="phone">
                                    Teléfono de contacto (Opcional)
                                </label>
                                <input
                                    type="text"
                                    name="phone"
                                    onChange={handleInputChange}
                                    className="form-control"
                                    defaultValue={card.phone}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div>
                                <label className="form-label" htmlhtmlFor="phone">
                                    Otro Teléfono de contacto (Opcional)
                                </label>
                                <input
                                    type="text"
                                    name="phone2"
                                    onChange={handleInputChange}
                                    className="form-control"
                                    defaultValue={card.phone2}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="mb-5 mt-5">
                        <h4><u>Datos profesionales</u></h4>
                    </div>
                    <div className="row g-5">
                        <div className="col-md-12">
                            <div>
                                <label className="form-label">Tu puesto de trabajo</label>
                                <input
                                    type="text"
                                    name="job"
                                    onChange={handleInputChange}
                                    className="form-control"
                                    defaultValue={card.job}
                                />
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div>
                                <label className="form-label">Descripción del puesto</label>
                                <textarea
                                    className="form-control"
                                    name="job_description"
                                    onChange={handleInputChange}
                                    rows="2"
                                    value={card.job_description}
                                >

                                </textarea>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div>
                                <label className="form-label" htmlhtmlFor="text">
                                    Departamento (Opcional)
                                </label>
                                <input
                                    type="email"
                                    className="form-control"
                                    name="department"
                                    onChange={handleInputChange}
                                    defaultValue={card.department}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div>
                                <label className="form-label" htmlhtmlFor="phone">
                                    Dirección del centro de trabajo (Opcional)
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="address"
                                    onChange={handleInputChange}
                                    defaultValue={card.address}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <hr className="my-6" />

            <div className="row g-6">
                <div className="col-md-6">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="mb-2">Aceptar llamadas</h4>
                            <p className="text-sm text-muted mb-6">
                                Activando esta opción, el usuario podrá recibir solicitudes de llamada
                            </p>
                            <div className="form-check form-switch">
                                <input className="form-check-input" htmlFor="accept_calls" type="checkbox" name="accept_calls" id="accept_calls" onChange={handleInputChange}
                                    checked={changeCard.accept_calls} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="d-flex mb-4 flex-row-reverse">
                <button type="button" className="btn btn-sm btn-neutral my-2 mx-2">
                    Cancelar
                </button>
                <button type="submit" onClick={handleSave} className="btn btn-sm btn-primary my-2 mx-2">
                    Guardar
                </button>
            </div>

            <div>
                {card.validation.comments && card.validation.comments.length > 0 && (
                    <div className="mb-5">
                        <h4>Comentarios y cambios de estado</h4>
                    </div>
                )}
                {card.validation.comments.map((comment, index) => (
                    <CommentCard key={index} comment={comment} />
                ))}
            </div>


            <div>
                <ul className="nav nav-tabs overflow-x border-0">
                    <li className="nav-item">
                        <a
                            href="#"
                            className={`nav-link ${activeTab === 'qr' ? 'active' : ''}`}
                            onClick={() => handleTabClick('qr')}
                        >
                            QR
                        </a>
                    </li>
                    <li className="nav-item">
                        <a
                            href="#"
                            className={`nav-link ${activeTab === 'link-card' ? 'active' : ''}`}
                            onClick={() => handleTabClick('link-card')}
                        >
                            Enlace Card
                        </a>
                    </li>
                    <li className="nav-item">
                        <a
                            href="#"
                            className={`nav-link ${activeTab === 'card-pic' ? 'active' : ''}`}
                            onClick={() => handleTabClick('card-pic')}
                        >
                            Foto de card
                        </a>
                    </li>
                </ul>

                {/* Contenido de las tabs */}
                <div className="tab-content">
                    {activeTab === 'qr' &&
                        <div className="tab-pane fade show active">
                            <div className="container-center">
                                <img className="fixed-height-image mt-4" alt="..." src={`${MEDIA_URL}${card.qr_url}`} />
                            </div>

                        </div>
                    }
                    {activeTab === 'link-card' &&
                        <div className="tab-pane fade show active ">
                            <a className="mt-5" href={card.card_url}>
                                <div className="mt-5 container-center">

                                    Enlace a página pública
                                </div></a>
                        </div>
                    }
                    {activeTab === 'card-pic' &&
                        <div className="tab-pane fade show active">
                            <div className="container-center">
                                <img className="fixed-height-image mt-4" alt="..." src={`${MEDIA_URL}${card.card_pic_url}`} />
                            </div>
                        </div>
                    }
                </div>
            </div>

        </div>
    );
};

export default CardContent;
