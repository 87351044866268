import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import NewCard from '../new_cards/pages/new';
import MainLayout from '../layouts/MainLayout';
import ListCards from '../list_cards/pages/list';
import ListUsers from '../users_admin/pages/users';
import Profile from '../profile/pages/profile';
import Login from '../base/pages/login';
import ProtectedRoute from '../components/protectedRoute';
import { ADMIN_USER, GENERAL_USER } from './permissions';
import { AuthProvider } from '../context/authcontext';
import Logout from '../base/pages/logout';
import { UserProvider } from '../context/userContext';
import { SettingProvider } from '../context/settingContext';
import { ListCardsProvider } from '../context/listCardsContext';
import { CommonProvider } from '../context/commonContext';


const CardsRoutes = () => {
    return (
        <CommonProvider>
            <AuthProvider>
                <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route path="/" element={<Navigate to="/login" />} />
                    <Route element={<MainLayout />}>
                        <Route path="/admin_users" element={<UserProvider><ProtectedRoute requiredPermissions={[ADMIN_USER]}><ListUsers /></ProtectedRoute></UserProvider>} />
                        <Route path="/new_card" element={<ProtectedRoute requiredPermissions={[ADMIN_USER, GENERAL_USER]}><NewCard /></ProtectedRoute>} />
                        <Route path="/list_cards" element={<ListCardsProvider><ProtectedRoute requiredPermissions={[ADMIN_USER, GENERAL_USER]}><ListCards /></ProtectedRoute></ListCardsProvider>} />
                        <Route path="/profile" element={<SettingProvider><ProtectedRoute requiredPermissions={[ADMIN_USER, GENERAL_USER]}><Profile /></ProtectedRoute></SettingProvider>} />
                    </Route>
                    <Route path="/logout" element={<Logout />} />
                </Routes>
            </AuthProvider>
        </CommonProvider>
    );
};

export default CardsRoutes;
