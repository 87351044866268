import { ADMIN_USER, GENERAL_USER } from "./permissions";

export const CardRouter = {
  label: 'Cards Menu',
  children: [
    {
      name: 'Nueva tarjeta',
      icon: 'bi bi-plus-circle',
      to: '/new_card',
      allowedRoles: [ADMIN_USER],
      active: true
    },
    {
      name: 'Todas las tarjetas',
      icon: 'bi bi-person-badge',
      to: '/list_cards',
      allowedRoles: [GENERAL_USER, ADMIN_USER],
      active: true
    },
    {
      name: 'Usuarios administradores',
      icon: 'bi bi-shield-lock',
      to: '/admin_users',
      allowedRoles: [ADMIN_USER],
      active: true
    }
  ]
};

export const ConfigurationRouter = {
  label: 'Configuración',
  children: [
    {
      name: 'Perfil',
      icon: 'bi bi-gear',
      to: '/profile',
      active: true
    },
    {
      name: 'Logout ',
      icon: 'bi bi-box-arrow-right',
      to: '/logout',
      active: true
    }
  ]
};


export default [
  CardRouter,
  ConfigurationRouter
];
