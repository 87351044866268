import React, { useContext, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import NavBarVertical from '../components/navBarVertical';
import NavBarTop from '../components/navBarTop';
import MobileNavBarVertical from '../components/mobileNavBarVertical';


const MainLayout = () => {


  return (
    <div className="d-flex flex-column flex-lg-row h-lg-full bg-surface-secondary">
      <NavBarVertical />
      <MobileNavBarVertical />

      <div className="flex-lg-1 h-screen overflow-y-lg-auto">

        <NavBarTop />

        <main className="py-6 bg-surface-secondary">
          <Outlet />
        </main>

      </div>

    </div>
  );
};

export default MainLayout;
