import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import imageProfile from '../../assets/img/img-profile.jpg'
import CardContent from './cardContent';

const EditCardModal = ({ onClose, card }) => {

    return (
        <Modal show={true} onHide={onClose} size="xl" centered
        >
            <Modal.Header closeButton>
                <Modal.Title>Editar una card</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <CardContent card={card} />
            </Modal.Body>
        </Modal>
    );
};

export default EditCardModal;
