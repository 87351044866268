import React, { useContext, useEffect, useState } from 'react';
import UserProfile from '../../components/userProfile';
import { API_URLS } from '../../api/apiConfig';
import { AuthContext } from '../../context/authcontext';
import { getWithAuth } from '../../api/api';
import { useSetting } from '../../context/settingContext';
import AutoDismissAlert from '../../components/autoDismiss';
import UserSetting from '../../components/userSetting';
import Loading from '../../list_cards/components/loading';

const Profile = () => {

    const { authData } = useContext(AuthContext);
    const settingContext = useSetting();
    const [isLoading, setIsLoading] = useState(true);


    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const data = await getWithAuth(API_URLS.ADMIN_GET, authData.token);
                settingContext.setCurrentUser(data.data[0]);
            } catch (error) {
                settingContext.setMessage("Ha ocurrido un error conectando con base de datos");
                settingContext.setAlertType("danger");
                settingContext.openAlertHeader();
            } finally {
                setIsLoading(false);
            }
        };
        fetchData();
    }, [settingContext.refreshUser]);

    return (
        <>
            <div className="container-fluid max-w-screen-md vstack gap-6">
                {settingContext.showAlertHeader && (
                    <div className="mb-4">
                        {/* Contenido para cuando isOkSaveConfig o isOkSavePassword son verdaderos */}
                        <AutoDismissAlert message={settingContext.message} alertType={settingContext.alertType} onClose={settingContext.closeAlertHeader} />
                        {/* Más contenido para cuando isOkSaveConfig o isOkSavePassword son verdaderos */}
                    </div>
                )}
                {isLoading ? (
                    // Mostrar el indicador de carga mientras se carga la API.
                    <Loading />
                ) : (
                    settingContext.currentUser && (
                        <UserSetting />
                    )
                )}
            </div>
        </>
    );
};

export default Profile; 