import React from 'react';

class TimestampDisplay extends React.Component {
    render() {
        const { timestamp } = this.props;

        // Crear un objeto Date a partir del timestamp
        const date = new Date(timestamp * 1000); // Multiplicamos por 1000 para convertir segundos a milisegundos

        // Formatear la fecha a un formato legible
        const formattedDate = date.toLocaleString();

        return (
            <div>
                <p>{formattedDate}</p>
            </div>
        );
    }
}

export default TimestampDisplay;
