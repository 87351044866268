// api.js
import axios from 'axios';

const axiosInstance = axios.create({
    timeout: 10000, // Tiempo de espera máximo para las solicitudes
    headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'accept': 'application/json',
    },
});

// Función para hacer una solicitud GET
export const getWithAuth = async (url, token) => {
    try {

        const response = await axiosInstance.get(url, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        return response.data;
    } catch (error) {
        throw error;
    }
};

// Función para hacer una solicitud GET
export const deleteWithAuth = async (url, token) => {
    try {

        const response = await axiosInstance.delete(url, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        return response.data;
    } catch (error) {
        throw error;
    }
};

// Función para hacer una solicitud POST
export const post = async (url, data) => {
    try {
        const response = await axiosInstance.post(url, data);
        return response.data;
    } catch (error) {
        throw error;
    }
};

// Función para hacer una solicitud POST
export const postWithAuth = async (url, token, data) => {
    try {

        const response = await axiosInstance.post(url, data, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error) {
        return error.response;
    }
};


// Función para hacer una solicitud POST
export const postWithAuthMultiPart = async (url, token, data) => {
    try {

        const response = await axiosInstance.post(url, data, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
            }
        });
        return response.data;
    } catch (error) {
        return error.response;
    }
};