import React, { createContext, useContext, useState } from 'react';

const UserContext = createContext();

export const UserProvider = ({ children }) => {

    //Gestion del contenido de los Alert
    const [message, setMessage] = useState('Esto un mensaje de prueba');
    const [alertType, setAlertType] = useState('success');


    //Gestion del Alert del Header
    const [showAlertHeader, setShowAlertHeader] = useState(false);
    const openAlertHeader = () => {
        setShowAlertHeader(true);
    };
    const claseAlertHeader = () => {
        setShowAlertHeader(false);
    };

    //Gestion del modal de nuevo usuario
    const [showNewUserModal, setShowNewUserModal] = useState(false);
    const openNewUserModal = () => {
        setShowNewUserModal(true);
    };
    const closeNewUserModal = () => {
        setShowNewUserModal(false);
    };


    //Gestion del modal Edicion de usuario
    const [showEditModal, setShowEditModal] = useState(false);
    const openEditUserModal = () => {
        setShowEditModal(true);
    };
    const closeEditUserModal = () => {
        setShowEditModal(false);
    };

    //Gestion del modal de ver usuario
    const [showUserProfileModal, setShowUserProfileModal] = useState(false);
    const openUserProfileModal = () => {
        setShowUserProfileModal(true);
    };
    const closeUserProfileModal = () => {
        setShowUserProfileModal(false);
    };

    //Gestion del modal de borrar un usuario
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const openDeleteModal = () => {
        setShowDeleteModal(true);
    };
    const closeDeleteModal = () => {
        setShowDeleteModal(false);
    };

    //Gestión de actualización de los usuarios
    const [refreshUserList, setRefreshUserList] = useState(false);
    const launchRefreshUserList = () => {
        setRefreshUserList(!refreshUserList);
    }

    //Selección de usuarios
    const [selectedUser, setSelectedUser] = useState(null);

    return (
        <UserContext.Provider value={{
            launchRefreshUserList,
            setSelectedUser,
            selectedUser,
            refreshUserList,
            closeDeleteModal,
            openDeleteModal,
            showDeleteModal,
            alertType,
            setAlertType,
            setMessage,
            message,
            showNewUserModal,
            showAlertHeader,
            openAlertHeader,
            claseAlertHeader,
            showNewUserModal,
            openNewUserModal,
            closeNewUserModal,
            showEditModal,
            openEditUserModal,
            closeEditUserModal,
            showUserProfileModal,
            openUserProfileModal,
            closeUserProfileModal
        }}>
            {children}
        </UserContext.Provider>
    );
};

export const useUser = () => {
    return useContext(UserContext);
};
