import React, { createContext, useContext, useState } from 'react';

const SettingContext = createContext();

export const SettingProvider = ({ children }) => {

    //Gestion del contenido de los Alert
    const [message, setMessage] = useState('Esto un mensaje de prueba');
    const [alertType, setAlertType] = useState('success');


    //Gestion del Alert del Header
    const [showAlertHeader, setShowAlertHeader] = useState(false);
    const openAlertHeader = () => {
        setShowAlertHeader(true);
    };
    const claseAlertHeader = () => {
        setShowAlertHeader(false);
    };

    //Gestión de actualización de API
    const [refreshUser, setRefreshUser] = useState(false);
    const launchRefreshUser = () => {
        setRefreshUser(!refreshUser);
    }

    //Seleccion de usuario
    const [currentUser, setCurrentUser] = useState(null);

    return (
        <SettingContext.Provider value={{
            launchRefreshUser,
            refreshUser,
            currentUser,
            setCurrentUser,
            alertType,
            setAlertType,
            setMessage,
            message,
            showAlertHeader,
            openAlertHeader,
            claseAlertHeader,
        }}>
            {children}
        </SettingContext.Provider>
    );
};

export const useSetting = () => {
    return useContext(SettingContext);
};
