import React, { useState } from 'react';

const Loading = ({ }) => {


    return (
        <>
            <div className="d-flex justify-content-center align-items-center" style={{ height: '80vh' }}>
                <div className="spinner-grow text-serveo" style={{ width: "10rem", height: "10rem" }} role="status">
                </div>
            </div>

        </>

    );
};

export default Loading;