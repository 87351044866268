import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { CardRouter } from '../routes/siteMaps';


const NavBarTop = () => {

    const location = useLocation();
    const currentPath = location.pathname;

    const currentRoute = CardRouter.children.find(route => route.to === currentPath);

    // Accede al valor "name" del elemento de la ruta actual
    const currentRouteName = currentRoute ? currentRoute.name : 'Configuración';


    return (
        <nav className="navbar navbar-light position-lg-sticky top-lg-0 d-none d-lg-block overlap-10 flex-none bg-white border-bottom px-0 py-3" id="topbar">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-sm col-12">
                        <h1 className="text-navbar-top">{currentRouteName}</h1>
                    </div>
                    <div className="col-auto"></div>

                </div>
            </div>
        </nav>
    );
};

export default NavBarTop;