import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import UserProfile from '../../components/userProfile';
import { API_URLS } from '../../api/apiConfig';

const EditUserModal = ({ onClose, user }) => {

    return (
        <Modal show={true} onHide={onClose} size="lg" centered
        >
            <Modal.Header closeButton>
                <Modal.Title>Editar usuario</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <UserProfile isNewUser={false} user={user} />
            </Modal.Body>
        </Modal>
    );
};

export default EditUserModal;
