// apiConfig.js
//const API_BASE_URL = 'http://0.0.0.0:8000'; // Reemplaza con la URL base de tu API
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const MEDIA_URL = process.env.REACT_APP_MEDIA_URL;

export const API_URLS = {
    MAIN: `${API_BASE_URL}/`,
    DOCS: `${API_BASE_URL}/docs/`,
    AUTH_CONFIRM_REGISTRY: `${API_BASE_URL}/confirm-registry/`,
    AUTH_LOGIN: `${API_BASE_URL}/login/`,
    AUTH_RESEND_CODE: `${API_BASE_URL}/resend-confirmation-code/`,
    AUTH_FORGOT_PASSWORD: `${API_BASE_URL}/forgot-password/`,
    AUTH_CONFIRM_FORGOT_PASSWORD: `${API_BASE_URL}/reset-password/`,
    AUTH_CHANGE_PASSWORD: `${API_BASE_URL}/change-password/`,
    AUTH_LOGOUT: `${API_BASE_URL}/logout/`,
    USERS_CONFIRM_EMAIL: `${API_BASE_URL}/users/confirm-email/`,
    USERS_RECONFIRM_EMAIL: `${API_BASE_URL}/users/re-confirm-email/`,
    USERS_REQUEST_CALL: (usersub) => `${API_BASE_URL}/cards/request-call/${usersub}/`,
    ADMIN_LIST: `${API_BASE_URL}/admin/`,
    ADMIN_GET: `${API_BASE_URL}/admin/profile/`,
    ADMIN_POST: `${API_BASE_URL}/admin/new/`,
    ADMIN_PUT_LOGGED: `${API_BASE_URL}/admin/edit/`,
    ADMIN_PATCH_PIC: `${API_BASE_URL}/admin/edit/pic/`,
    ADMIN_DELETE: (adminsub) => `${API_BASE_URL}/admin/delete/${adminsub}/`,
    ADMIN_PUT: (adminsub) => `${API_BASE_URL}/admin/edit/${adminsub}/`,
    CARDS_GET: (usersub) => `${API_BASE_URL}/cards/${usersub}/`,
    CARDS_POST: `${API_BASE_URL}/cards/new/`,
    CARDS_VCARD_GET: (usersub) => `${API_BASE_URL}/cards/vcard/${usersub}/`,
    CARDS_CONFIRM_NEW_CARD: `${API_BASE_URL}/cards/new/confirm/`,
    CARDS_LIST: `${API_BASE_URL}/admin/cards/`,
    CARDS_LIST_PENDING: `${API_BASE_URL}/admin/cards/pending/`,
    ADMIN_CARDS_POST: `${API_BASE_URL}/admin/cards/new/`,
    CARDS_PUT: (card_id) => `${API_BASE_URL}/admin/cards/edit/${card_id}/`,
    CARDS_CHANGE_STATUS: (card_id) => `${API_BASE_URL}/admin/cards/change-status/${card_id}/`,
    CARDS_DELETE: (card_id) => `${API_BASE_URL}/admin/cards/delete/${card_id}/`,
};
