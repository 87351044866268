import React, { useContext, useState } from 'react';
import logoCorbital from '../assets/img/logo_serveo_grande_normal.svg'
import { Link, NavLink } from 'react-router-dom';
import { CardRouter, ConfigurationRouter } from '../routes/siteMaps';
import imageProfile from '../assets/img/img-profile.jpg'
import { AuthContext } from '../context/authcontext';

const MobileNavBarVertical = () => {
    const { authData } = useContext(AuthContext);

    return (

        <nav className="navbar show navbar-vertical h-lg-screen navbar-expand-lg px-0 py-3 navbar-light bg-white border-bottom border-bottom-lg-0 border-end-lg scrollbar d-lg-none"
            id="sidebar">
            <div className="container-fluid"><button className="navbar-toggler ms-n2" type="button" data-bs-toggle="collapse"
                data-bs-target="#sidebarCollapse" aria-controls="sidebarCollapse" aria-expanded="false"
                aria-label="Toggle navigation"><span className="navbar-toggler-icon"></span></button> <a
                    className="navbar-brand d-inline-block py-lg-2 mb-lg-5 px-lg-6 me-0"><img
                        src={logoCorbital} className="small-logo" alt="..."></img></a>
                <div className="navbar-user d-lg-none">
                    <div className="dropdown"><a href="#" id="sidebarAvatar" role="button" data-bs-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                        <div className="avatar-parent-child"><img alt="..." src={imageProfile}
                            className="avatar avatar- rounded-circle"></img><span
                                className="avatar-child avatar-badge bg-success"></span></div>
                    </a>
                        <div className="dropdown-menu dropdown-menu-end" aria-labelledby="sidebarAvatar">
                            {ConfigurationRouter.children.map((route, index) => (
                                <a key={index} href={route.to} className="dropdown-item">{route.name}</a>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="collapse navbar-collapse" id="sidebarCollapse">
                    <ul className="navbar-nav">
                        {CardRouter.children.map((route, index) => {
                            // Verificar si el rol actual tiene acceso a la ruta
                            if (!route.allowedRoles || route.allowedRoles.includes(authData.type_user)) {
                                return (
                                    <li key={index} className="nav-item">
                                        <NavLink
                                            to={route.to}
                                            className="nav-link nav-link-movile py-2"
                                        >
                                            <i className={`bi ${route.icon}`}></i> {route.name}
                                        </NavLink>
                                    </li>
                                );
                            }
                            return null;
                        })}
                    </ul>

                </div>
            </div>
        </nav >


    );
};

export default MobileNavBarVertical;