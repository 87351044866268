import React, { useEffect, useState, useContext } from 'react';
import imageProfile from '../../assets/img/img-profile.jpg'
import DeleteModal from '../../components/deleteModal';
import EditUserModal from '../components/editUserModal';
import NewUserModal from '../components/newUserModal';
import { getWithAuth } from '../../api/api';
import { API_URLS } from '../../api/apiConfig';
import AutoDismissAlert from '../../components/autoDismiss';
import { AuthContext } from '../../context/authcontext';
import { useUser } from '../../context/userContext';
import UserList from '../components/userList';
import Loading from '../../list_cards/components/loading';

const ListUsers = () => {

    const { authData } = useContext(AuthContext);
    const userContext = useUser();
    const [isLoading, setIsLoading] = useState(true);


    const [adminData, setAdminData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const data = await getWithAuth(API_URLS.ADMIN_LIST, authData.token);
                setAdminData(data.data);
            } catch (error) {
                userContext.setMessage("Ha ocurrido un error conectando con el servidor");
                userContext.setAlertType("danger");
                userContext.openAlertHeader();
                console.error('Error fetching admin users data:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [userContext.refreshUserList]);

    const [searchTerm, setSearchTerm] = useState("");
    const [selectedCategory, setSelectedCategory] = useState('all');

    const filteredUsers = adminData.filter((user) => {
        const fullName = `${user.name} ${user.surname}`.toLowerCase();
        const userEmail = user.email.toLowerCase();

        // Filtra por categoría
        if (selectedCategory === 'all') {
            // Si se selecciona "Ver todos", no aplicamos ningún filtro de categoría
            return fullName.includes(searchTerm.toLowerCase()) || userEmail.includes(searchTerm.toLowerCase());
        } else if (selectedCategory === 'RRHH') {
            // Filtrar por usuarios de RRHH
            return !user.is_superadmin && (fullName.includes(searchTerm.toLowerCase()) || userEmail.includes(searchTerm.toLowerCase()));
        } else if (selectedCategory === 'Admin') {
            // Filtrar por usuarios de Admin
            return user.is_superadmin && (fullName.includes(searchTerm.toLowerCase()) || userEmail.includes(searchTerm.toLowerCase()));
        }

        return false; // En caso de categoría no válida
    });


    return (
        <div className="container-fluid ">
            {userContext.showAlertHeader && (
                <div className="">
                    {/* Contenido para cuando isOkSaveConfig o isOkSavePassword son verdaderos */}
                    <AutoDismissAlert message={userContext.message} alertType={userContext.alertType} onClose={userContext.closeAlertHeader} />
                    {/* Más contenido para cuando isOkSaveConfig o isOkSavePassword son verdaderos */}
                </div>
            )}
            {isLoading ? (
                // Mostrar el indicador de carga mientras se carga la API.
                <Loading />
            ) : (
                <div className="vstack gap-4 mt-4">
                    <div className="card">
                        <div className="card-header d-flex align-items-center">
                            <div className="scrollable-x">
                                <div className="btn-group">
                                    <a href="#" className={`btn btn-sm btn-neutral text-primary ${selectedCategory === 'all' ? 'active' : ''}`} onClick={() => setSelectedCategory('all')}>
                                        Ver todos
                                    </a>
                                    <a href="#" className={`btn btn-sm btn-neutral ${selectedCategory === 'RRHH' ? 'active' : ''}`} onClick={() => setSelectedCategory('RRHH')}>
                                        RRHH <span className="text-muted text-xs"></span>
                                    </a>
                                    <a href="#" className={`btn btn-sm btn-neutral ${selectedCategory === 'Admin' ? 'active' : ''}`} onClick={() => setSelectedCategory('Admin')}>
                                        Admin <span className="text-muted text-xs"></span>
                                    </a>
                                </div>
                            </div>
                            <div className="ms-auto hstack gap-2">
                                <div className="input-group input-group-sm input-group-inline"><span className="input-group-text pe-2"><i
                                    className="bi bi-search"></i> </span>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Buscar"
                                        aria-label="Buscar"
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                    />
                                </div>
                                <div><button type="button" className="btn btn-sm px-3 btn-primary d-flex align-items-center" onClick={() => userContext.openNewUserModal()}><i
                                    className="bi bi-plus-square-dotted me-2"></i> <span>Nuevo</span>
                                </button></div>
                            </div>

                        </div>
                        <div className="table-responsive">
                            <table className="table table-hover table-nowrap">
                                <thead className="table-light">
                                    <tr>
                                        <th scope="col">Nombre</th>
                                        <th scope="col">Correo electrónico</th>
                                        <th scope="col">Acceso</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredUsers.map((user, index) => (
                                        <UserList key={index} user={user} />
                                    ))}
                                </tbody>
                            </table>
                            {userContext.showDeleteModal && (
                                <DeleteModal
                                    title={"Eliminar usuario"}
                                    onClose={() => {
                                        userContext.closeDeleteModal()
                                        userContext.launchRefreshUserList()
                                    }
                                    }
                                    message1={`¿Estás seguro de eliminar el usuario ${userContext.selectedUser.name} ${userContext.selectedUser.lastname}?`}
                                    message2="¡Cuidado! Eliminar el usuario implica eliminar tambien todas sus cards asociadas"
                                    apiUrl={API_URLS.ADMIN_DELETE(userContext.selectedUser.sub)}
                                />
                            )}
                            {userContext.showEditModal && (
                                <EditUserModal
                                    user={userContext.selectedUser}
                                    onClose={() => {
                                        userContext.closeEditUserModal()
                                        userContext.launchRefreshUserList()
                                    }}
                                />
                            )}
                            {userContext.showNewUserModal && (
                                <NewUserModal
                                    onClose={() => {
                                        userContext.closeNewUserModal();
                                        userContext.launchRefreshUserList()
                                    }}
                                />
                            )}
                        </div>
                        <div className="card-footer border-0 py-5"><span className="text-muted text-sm"></span></div>
                    </div>
                </div>
            )}
        </div >
    );
};

export default ListUsers;