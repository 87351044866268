import React, { useContext, useState } from 'react';
import { Modal, Button, Alert } from 'react-bootstrap';
import AutoDismissAlert from './autoDismiss';
import { deleteWithAuth } from '../api/api';
import { AuthContext } from '../context/authcontext';

const DeleteModal = ({ onClose, title, message1, message2, apiUrl }) => {

    const [showAlertHeader, setShowAlertHeader] = useState(false);
    const { authData } = useContext(AuthContext);

    const [message, setMessage] = useState('Esto un mensaje de prueba');
    const [alertType, setAlertType] = useState('success');

    const handleDelete = async () => {
        try {
            const data = await deleteWithAuth(apiUrl, authData.token);
            onClose();
        } catch (error) {
            setMessage("Ha ocurrido un error inesperado");
            setAlertType("danger");
            setShowAlertHeader(true);
            console.error('Error fetching admin users data:', error);
        }
    };

    return (
        <Modal show={true} onHide={onClose} size="lg" centered
        >
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            {showAlertHeader && (
                <div className="m-5">
                    {/* Contenido para cuando isOkSaveConfig o isOkSavePassword son verdaderos */}
                    <AutoDismissAlert message={message} alertType={alertType} onClose={() => setShowAlertHeader(false)} />
                    {/* Más contenido para cuando isOkSaveConfig o isOkSavePassword son verdaderos */}
                </div>
            )}
            <Modal.Body>
                <span>{message1}</span>
                <p>{message2}</p>
            </Modal.Body>
            <Modal.Footer>

                <Button variant="primary" onClick={() => {
                    handleDelete();
                }}>Eliminar</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default DeleteModal;