import React, { useState } from 'react';
import imageProfile from '../../assets/img/img-profile.jpg'
import TimestampDisplay from '../../components/timeStampShow';
import { MEDIA_URL } from '../../api/apiConfig';
import { useUser } from '../../context/userContext';

const UserList = ({ user }) => {

    const userContext = useUser();
    const imagePic = user.user_pic_url ? `${MEDIA_URL}${user.user_pic_url}` : imageProfile;

    return (

        <tr key={user.id}>
            <td><img alt="..." src={imagePic} className="avatar avatar-sm rounded-circle me-2"></img> <a
                className="text-heading text-primary-hover font-semibold" href="#">{user.name} {user.lastname}</a></td>
            <td>{user.email}</td>
            <td>

                {user.is_superadmin && (
                    <span className="badge text-uppercase bg-soft-primary text-primary rounded-pill">Admin</span>
                )}

                {!user.is_superadmin && (
                    <span className="badge text-uppercase bg-soft-primary text-primary rounded-pill">RRHH</span>

                )}

            </td>


            <td className="text-end"><a href="#" className="btn btn-sm btn-square btn-neutral me-1" onClick={() => {
                userContext.setSelectedUser(user); // Establecer el usuario seleccionado
                userContext.openEditUserModal(); // Abrir el modal de edición
            }}><i
                className="bi bi-pencil"></i> </a><button type="button"
                    className="btn btn-sm btn-square btn-neutral text-danger-hover" onClick={() => {
                        userContext.setSelectedUser(user);
                        userContext.openDeleteModal();
                    }}><i
                        className="bi bi-trash"></i></button></td>
        </tr>

    );
};

export default UserList;
