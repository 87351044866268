import React, { useContext, useEffect, useState } from 'react';
import CardUser from '../components/card';
import AutoDismissAlert from '../../components/autoDismiss';
import { useListCards } from '../../context/listCardsContext';
import { API_URLS } from '../../api/apiConfig';
import { getWithAuth } from '../../api/api';
import { AuthContext } from '../../context/authcontext';
import DeleteModal from '../../components/deleteModal';
import EditCardModal from '../components/editCardModal';
import ChangeStateCardModal from '../components/cardChangeState';

const ListCards = () => {

    const { authData } = useContext(AuthContext);
    const listCardsContext = useListCards();
    const [cardsList, setListCards] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const [searchTerm, setSearchTerm] = useState("");
    const [selectedCategory, setSelectedCategory] = useState('all');

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const data = await getWithAuth(API_URLS.CARDS_LIST, authData.token);
                setListCards(data.data);
            } catch (error) {
                listCardsContext.setMessage("Ha ocurrido un error conectando con el servidor")
                listCardsContext.setAlertType("danger")
                listCardsContext.openAlertHeader()
                console.error('Error getting cards:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [listCardsContext.apiSuccess]);

    const filteredCards = cardsList.filter((card) => {
        const fullName = `${card.name} ${card.lastname}`.toLowerCase();
        const userEmail = card.email.toLowerCase();
        const userJob = card.job.toLowerCase();

        if (selectedCategory === 'all') {
            return fullName.includes(searchTerm.toLowerCase()) || userEmail.includes(searchTerm.toLowerCase()) || userJob.includes(searchTerm.toLowerCase());
        } else if (selectedCategory === 'accepted') {
            return card.validation.status === 'accepted' && (fullName.includes(searchTerm.toLowerCase()) || userEmail.includes(searchTerm.toLowerCase()) || userJob.includes(searchTerm.toLowerCase()));
        } else if (selectedCategory === 'pending') {
            return card.validation.status === 'pending' && (fullName.includes(searchTerm.toLowerCase()) || userEmail.includes(searchTerm.toLowerCase()) || userJob.includes(searchTerm.toLowerCase()));
        }

        return false;
    });

    return (
        <div className="container-fluid">
            {listCardsContext.showAlertHeader && (
                <div className="">
                    <AutoDismissAlert message={listCardsContext.message} alertType={listCardsContext.alertType} onClose={listCardsContext.closeAlertHeader} />
                </div>
            )}
            {isLoading ? (
                <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                    <div className="spinner-grow text-serveo" style={{ width: "10rem", height: "10rem" }} role="status">
                    </div>
                </div>
            ) : (
                <div className="vstack gap-6">
                    <div className="d-flex flex-column flex-md-row gap-3 justify-content-between">
                        <div className="d-flex gap-3">
                            <div className="input-group input-group-sm input-group-inline"><span className="input-group-text pe-2"><i
                                className="bi bi-search"></i> </span>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Buscar"
                                    aria-label="Buscar"
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                            </div>
                            <div></div>
                        </div>
                        <div className="btn-group">
                            <a href="#" className={`btn btn-sm btn-neutral ${selectedCategory === 'all' ? 'active text-primary' : ''}`} onClick={() => setSelectedCategory('all')}>Todas</a>
                            <a href="#" className={`btn btn-sm btn-neutral ${selectedCategory === 'accepted' ? 'active text-primary' : ''}`} onClick={() => setSelectedCategory('accepted')}>Aceptadas</a>
                            <a href="#" className={`btn btn-sm btn-neutral ${selectedCategory === 'pending' ? 'active text-primary' : ''}`} onClick={() => setSelectedCategory('pending')}>Pendientes</a>
                        </div>
                    </div>
                    {listCardsContext.showAlertHeader && (
                        <div className='mb-2'>
                            <AutoDismissAlert message={listCardsContext.message} alertType={listCardsContext.alertType} onClose={listCardsContext.closeAlertHeader} />
                        </div>
                    )}
                    <div className="row g-6">
                        {filteredCards.map((card, index) => (
                            <CardUser key={index} card={card} />
                        ))}
                    </div>
                </div>
            )}
            {listCardsContext.showDeleteModal && (
                <DeleteModal
                    title={"Eliminar card"}
                    onClose={() => {
                        listCardsContext.closeDeleteModal()
                        listCardsContext.changeApiSuccess()
                    }
                    }
                    message1={`¿Estás seguro de eliminar la card de ${listCardsContext.selectedCard.name}?`}
                    message2="¡Cuidado, esta card ya no será accesible nunca más!"
                    apiUrl={API_URLS.CARDS_DELETE(listCardsContext.selectedCard.id)}
                />
            )}
            {listCardsContext.showEditModal && (
                <EditCardModal
                    card={listCardsContext.selectedCard}
                    onClose={() => listCardsContext.closeEditUserModal()}
                />
            )}
            {listCardsContext.showChangeStateModal && (
                <ChangeStateCardModal
                    card={listCardsContext.selectedCard}
                    onClose={() => {
                        listCardsContext.closeChangeStateModal();
                        listCardsContext.changeApiSuccess()
                    }
                    }
                />
            )}
        </div>
    );
};

export default ListCards;
