import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import CardsRoutes from './routes';

function App() {
  return (
    <Router>
      <CardsRoutes />
    </Router>
  );
}

export default App;
