import React, { useContext, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import imageProfile from '../../assets/img/img-profile.jpg'
import CardContent from './cardContent';
import { postWithAuth } from '../../api/api';
import { API_URLS } from '../../api/apiConfig';
import { AuthContext } from '../../context/authcontext';
import AutoDismissAlert from '../../components/autoDismiss';
import { useListCards } from '../../context/listCardsContext';

const ChangeStateCardModal = ({ onClose, card }) => {
    const [changeStatusCard, setChangedStatusCard] = useState({
        status: 'accepted', // Valor inicial predeterminado
        comment: '',
    });
    const { authData } = useContext(AuthContext);
    const listCardsContext = useListCards();


    const [showAlertHeader, setShowAlertHeader] = useState(false);
    const [message, setMessage] = useState('Esto un mensaje de prueba');
    const [alertType, setAlertType] = useState('danger');

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setChangedStatusCard({ ...changeStatusCard, [name]: value });
    };

    const handleSave = async (e) => {
        e.preventDefault();
        try {
            const response = await postWithAuth(API_URLS.CARDS_CHANGE_STATUS(card.id), authData.token, changeStatusCard);

            if (response.status === 'success') {
                onClose();
            } else if (response.status === 404) {
                setMessage(response.data.detail);
                setShowAlertHeader(true);
            } else {
                setMessage("Error inesperado");
                setShowAlertHeader(true);
            }

        } catch (error) {
            console.error('Error creating user admin:', error);
            setMessage("Ha ocurrido un error inesperado");
            setShowAlertHeader(true);
        }
    };

    return (
        <Modal show={true} onHide={onClose} size="l" centered
        >
            <Modal.Header closeButton>
                <Modal.Title>Cambia el estado de esta Card</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row g-5">
                    {showAlertHeader && (
                        <div>
                            {/* Contenido para cuando isOkSaveConfig o isOkSavePassword son verdaderos */}
                            <AutoDismissAlert message={message} alertType={alertType} onClose={() => setShowAlertHeader(false)} />
                            {/* Más contenido para cuando isOkSaveConfig o isOkSavePassword son verdaderos */}
                        </div>
                    )}
                    <div className="col-md-">
                        <div><label className="form-label">Estado de la card</label> <select onChange={handleInputChange} name="status" className="form-select" aria-label="Default select example">
                            <option value="accepted">Verificado</option>
                            <option value="pending">Pendiente</option>
                            <option value="rejected">Rechazado</option>
                        </select></div>
                    </div>
                    <div className="col-md-12">
                        <div>
                            <label className="form-label">Comentario explicativo</label>
                            <textarea className="form-control" name="comment" onChange={handleInputChange} placeholder='Específica un comentario que permita al trabajador conocer el motivo del posible rechazo' rows="2"></textarea>

                        </div>
                    </div>
                </div>
                <div className="col-12 text-end mt-4"><button type="button" className="btn btn-sm btn-neutral me-2">Cancelar</button>
                    <button type="submit" className="btn btn-sm btn-primary" onClick={handleSave}>Guardar</button></div>
            </Modal.Body>
        </Modal>
    );
};

export default ChangeStateCardModal;
