import React, { useContext, useState } from 'react';
import logoCorbital from '../assets/img/logo_serveo.png'
import { Link, NavLink } from 'react-router-dom';
import { CardRouter, ConfigurationRouter } from '../routes/siteMaps';
import { AuthContext } from '../context/authcontext';

const NavBarVertical = () => {

    const { authData } = useContext(AuthContext);

    return (
        <nav className="navbar show navbar-vertical h-lg-screen navbar-expand-lg px-0 py-3 navbar-light bg-white border-bottom border-bottom-lg-0 border-end-lg d-none d-md-flex">

            <div className="container-fluid">
                <button className="navbar-toggler ms-n2" type="button" data-bs-toggle="collapse"
                    data-bs-target="#sidebarCollapse" aria-controls="sidebarCollapse" aria-expanded="false"
                    aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <a className="navbar-brand py-lg-2 mb-lg-5 px-lg-6 me-0 d-flex flex-column align-items-center">
                    <img src={logoCorbital} alt="..."></img>
                </a>

                <div className="navbar-user d-lg-none">
                    <div className="dropdown">
                        <a href="#" id="sidebarAvatar" role="button" data-bs-toggle="dropdown" aria-haspopup="true"
                            aria-expanded="false">
                            <div className="avatar-parent-child">
                                <img alt="..." src="/img/people/img-profile.jpg" className="avatar avatar- rounded-circle"></img>
                                <span className="avatar-child avatar-badge bg-success"></span>
                            </div>
                        </a>
                        <div className="dropdown-menu dropdown-menu-end" aria-labelledby="sidebarAvatar">
                            <a href="#" className="dropdown-item">Profile</a>
                            <a href="#" className="dropdown-item">Settings</a>
                            <a href="#" className="dropdown-item">Billing</a>
                            <hr className="dropdown-divider"></hr>
                            <a href="#" className="dropdown-item">Logout</a>
                        </div>
                    </div>
                </div>

                <div className="collapse navbar-collapse" id="sidebarCollapse">
                    <div className="mt-auto"></div>
                    <ul className="navbar-nav d-flex flex-column align-items-center">
                        {CardRouter.children.map((route, index) => {
                            // Verificar si el rol actual tiene acceso a la ruta
                            if (!route.allowedRoles || route.allowedRoles.includes(authData.type_user)) {
                                return (
                                    <li key={index} className="nav-item d-flex flex-column align-items-center mt-7">
                                        <NavLink
                                            to={route.to}
                                            className="nav-link py-2 d-flex flex-column align-items-center"
                                        >
                                            <i className={`bi ${route.icon} d-flex flex-column align-items-center`}></i>
                                        </NavLink>
                                    </li>
                                );
                            }
                            return null;
                        })}

                    </ul>

                    <div className="mt-auto"></div>

                    <hr className="navbar-divider my-4 opacity-70"></hr>

                    <div className="my-4 position-relative">
                        <ul className="navbar-nav d-flex flex-column align-items-center">

                            {ConfigurationRouter.children.map((route, index) => (
                                <li key={index} className="nav-item d-flex flex-column align-items-center mt-3">
                                    <NavLink
                                        to={route.to}
                                        className="nav-link py-2 d-flex flex-column align-items-center"

                                    >
                                        <i className={`bi ${route.icon} d-flex flex-column align-items-center`}></i>
                                    </NavLink>
                                </li>
                            ))}

                        </ul>
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default NavBarVertical;