import React, { createContext, useContext, useState } from 'react';
import { ADMIN_USER, GENERAL_USER } from '../routes/permissions';

// Crea el contexto de autenticación
export const AuthContext = createContext();

// Proveedor del contexto de autenticación
export const AuthProvider = ({ children }) => {

    const [authData, setAuthData] = useState({
        isAuthenticated: false,
        token: null,
        type_user: null
    });

    const login = (token, is_superadmin) => {
        let type_user;

        if (is_superadmin === true) {
            type_user = ADMIN_USER;
        } else {
            type_user = GENERAL_USER;
        }

        setAuthData({
            isAuthenticated: true,
            token,
            type_user,
        });
    };

    const logout = () => {
        setAuthData({
            isAuthenticated: false,
            token: null,
            type_user: null
        });
    };

    return (
        <AuthContext.Provider value={{ authData, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    return useContext(AuthContext);
};
